import FooterOrange from "./FooterOrange";
import FooterBlack from "./FooterBlack";

const Footer = () => {
    return ( 
        <>
            <FooterOrange/>
            <FooterBlack />
        </>
     );
}
 
export default Footer;