import styled from "styled-components";
import SectionLable from "../SectionLable/SectionLable";
import AnimationFadeOut from "../Animation/AnimationFadeOut"; 


const ProjectHeaderBackground = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1b;
    padding-top: 50px;
    padding-bottom: 90px;

    @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 50px;
    }      
`

const ProjectVideoStyled = styled.video`
    width: 100%;
    max-width: 1600px;
    
    /* видео по центру */
    display: block;
    margin-left: auto;
    margin-right: auto;
    
    margin-top: 80px;
    object-fit: cover;

    @media (max-width: 767px) {
        margin-top: 40px;
    }  
`

const ProjectVideoPoster = styled.img`    
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1600px;
    height: auto;
    margin-top: 80px;

    @media (max-width: 767px) {
        margin-top: 40px;
    }      
`

const ProjectHeader = (props) => {
    return ( 
        <ProjectHeaderBackground>
            <SectionLable textColor="#fc6435">
                {props.projectItem.title}
            </SectionLable>

            {/* Если есть видео, то показываем, если нет, только заглушку */}            
            {props.projectItem.video_path ?     
                <ProjectVideoStyled controls loop poster={props.projectItem.video_poster}>
                    <source src={props.projectItem.video_path} type="video/mp4" />
                </ProjectVideoStyled>
                :
                <AnimationFadeOut>
                    <ProjectVideoPoster src={props.projectItem.video_poster} />
                </AnimationFadeOut>
            }            
        </ProjectHeaderBackground>
     );
}
 
export default ProjectHeader;