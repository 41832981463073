import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import SectionLable from '../components/SectionLable/SectionLable';
import BurgerMenu from '../components/Header/BurgerMenu';
import { Outlet } from "react-router-dom";
import AllNews from '../components/AllNews/AllNews';
import AllNewsBackground from '../components/AllNews/AllNewsBackground';


function AllNewsPage() {
    return (
        <>
            {/* Место для размещения модального окна с новостью */}
            <Outlet/>

            <BurgerMenu/>
            <Header/>
            <AllNewsBackground>
                <SectionLable textColor="#f9f9f9">
                    NEWS
                </SectionLable>
                <AllNews />
            </AllNewsBackground>
            <Footer/>
        </>
    );
}
  
export default AllNewsPage;
