import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import BurgerMenu from '../components/Header/BurgerMenu';
import ProjectsList from '../components/Projects/ProjectsList';


function ProjectsPage() {

    return (
      <>        
        <BurgerMenu/>
        <Header/>
        <ProjectsList numProjectsToLoad={'16'} />
        <Footer/>
      </>
    );
  }
  
  export default ProjectsPage;
  