import { createContext } from 'react';
import { useState } from 'react';


export const MFAContext = createContext(null);


const MFAContextProvider = ({children}) => {
    // В зависимости от разрешения подгружаем новости разными порциями
    let newsToLoad = 3;
    if(window.innerWidth < 1260) {
        newsToLoad = 2;
    } 
    // else if (window.innerWidth > 1020 && window.innerWidth < 1360) {
    //     newsToLoad = 3;
    // }

    const [newsLimit, setNewsLimit] = useState(newsToLoad);
    const [totalNews, setTotalNews] = useState(0);

    return (
        <MFAContext.Provider value={{
            newsLimit, 
            setNewsLimit,
            newsToLoad,
            totalNews,
            setTotalNews,
        }} >
            {children}
        </MFAContext.Provider>                
    );
}
 
export default MFAContextProvider;
