import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";


const StyledProjectsItem = styled.div`
    position: relative;
    transition: all .2s ease-in-out;

    @media (min-width: 1280px) {
        &:hover {
            transform: scale(0.95);
        }
    }  
`

const ProjectPoster = styled.img`
    width: 100%;
    height: auto;
`

const ProjectLeftLable = styled.div`
    position: absolute;
    left: 20px;
    bottom: 5px;
    letter-spacing: 3px;

    p:nth-child(1) {
        color: #f9f9f9;
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 10px;
    } 
    /* 
        Насктройки названия проекта, когда оно было поверх постера
        p:nth-child(2) {
        color: #f9f9f9;
        font-size: 1.25rem;
        text-transform: uppercase;
        line-height: 1.5rem;
    } */
`

const ProjectRightLable = styled.div`
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: #f9f9f9;
    font-size: 0.9rem;
    font-weight: 300;
    display: flex;
    flex-wrap: nowrap;
    
    img {
        width: 8px !important;
        align-self: center;
        justify-self: center;        
        margin-left: 5px;
    }
`

const ProjectHeaderContainer = styled.div`
    color: #f9f9f9;
    font-size: 1.25rem;
    text-transform: uppercase;
    line-height: 1.5rem;    
    min-height: 50px;
    color: #FC6435;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`


const ProjectsItem = (props) => {
    return(
        <a href={`/projects/${props.projectID}/`}>
            <StyledProjectsItem>
                <ProjectHeaderContainer>
                    <p>{props.title}</p>
                </ProjectHeaderContainer>
                <ProjectPoster src={props.poster} />
                <ProjectLeftLable>
                    <p>{props.category}</p>
                    {/* 
                    Раньше название проекта было тут, потом перенесли вверх над постером
                    <p dangerouslySetInnerHTML={{__html: props.title}} /> 
                    */} 
                </ProjectLeftLable>
                <ProjectRightLable>
                    <p>read more</p>
                    <img src='/images/arrow_white.png' />
                </ProjectRightLable>
            </StyledProjectsItem>
        </a>
    );
}

export default ProjectsItem;
