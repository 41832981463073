import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import BurgerMenu from '../components/Header/BurgerMenu';
import WideSlider from '../components/WideSlider/WideSlider';
import { useParams } from "react-router-dom";
import ProjectsService from '../API/ProjectsService';
import { useState, useEffect } from 'react';
import { useFetching } from '../hooks/useFetching';
import { baseURL } from '..';
import ProjectDescription from '../components/Projects/ProjectDescription';
import ProjectsSlider from '../components/Projects/ProjectsSlider';
import ProjectSingleScreen from '../components/Projects/ProjectSingleScreen';
import { motion } from "framer-motion";
import {animationOnce} from '../index';
import ProjectScreens from '../components/Projects/ProjectScreens';
import ProjectHeader from '../components/Projects/ProjectHeader';
import ProjectSlides from '../components/Projects/ProjectSlides';


function ProjectsIdPage() {
    const params = useParams();
    const [projectItem, setProjectItem] = useState({});
    const [projectScreens, setprojectScreens] = useState([]);

    const [fetchProjectID, isProjectIDLoading, projectIDError] = useFetching(async (projectId) => {
        const response = await ProjectsService.getProjectItem(projectId);
        // генерим новый словарь с проектом, убрав тег переноса строки(br) из заголовка
        const project = { ...response.data, 
            title: response.data['title'].replaceAll('<br>', '').replaceAll('<BR>', '')
        }
        setProjectItem(project);

        // теперь получаем список скринов
        const response2 = await ProjectsService.getProjectScreens(projectId);
        setprojectScreens(response2.data)
    })

    useEffect(() => {
        fetchProjectID(params.id);
    }, [])

    return (
      <>        
        <BurgerMenu/>
        <Header/>
        <ProjectHeader projectItem={projectItem} />
        <ProjectDescription projectItem={projectItem} />
        <ProjectScreens projectScreens={projectScreens} />
        <ProjectSlides currentProject={params.id} />
        <Footer/>
      </>
    );
  }
  
  export default ProjectsIdPage;