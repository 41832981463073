import NewsBackground from "./NewsBackground";
import NewsContainer from "./NewsContainer";
import NewsItem from "./NewsItem/NewsItem";
import { useState, useEffect, useContext } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import NewsService from "../../API/NewsService";
import { MFAContext } from "../../hoc/MFAContextProvider";
import { useFetching } from "../../hooks/useFetching";
import Loader from "../Loader/Loader";
import "../../styles/news.css";
import SectionLable from "../SectionLable/SectionLable";
import NewsFooter from "./NewsFooter";
import AnimationLeft from "../Animation/AnimationLeft";
import SeeAllItemsLink from "../SeeAllItemsLink";


const News = () => {
    const [newsList, setNewsList] = useState([]);
    const {
        newsLimit, // Сколько новостей грузить
    } = useContext(MFAContext);

    const [fetchNews, isNewsLoading, newsError] = useFetching(async (newsLimit) => {
        const response = await NewsService.getNews(newsLimit);
        setNewsList(response.data.results);
    })

    useEffect(() => {
        fetchNews(newsLimit);
    }, [newsLimit,])    

    return (
        <>
        <NewsBackground>
            <SectionLable id={'news'} >
                NEWS
            </SectionLable>         
            <NewsContainer>           
                    {newsList.map( (item) =>
                        <AnimationLeft key={item.id}>
                            <NewsItem item={item} />
                        </AnimationLeft>
                    )}
            </NewsContainer>

            {/* Если происходит подгрузка новостей, показываем анимацию */}
            {isNewsLoading &&
                <Loader marginBottom={"30px"} />
            }

        </NewsBackground>
        <SeeAllItemsLink to="/allnews/">
            ALL NEWS
        </SeeAllItemsLink>
        <NewsFooter />
        </>
    );
}
 
export default News;
