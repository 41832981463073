import {
    Route, Routes
} from "react-router-dom";
import HomePage from "../pages/HomePage";
import NotFound from "../pages/NotFound";
import NewsIdPage from "../pages/NewsIdPage";
import TestPage from "../pages/TestPage";
import ProjectsPage from "../pages/ProjectsPage";
import ProjectsIdPage from "../pages/ProjectsIdPage";
import AllNewsPage from "../pages/AllNewsPage";
import AboutPage from "../pages/AboutPage";


const AppRouter = () => {

    return (
        <Routes>      
            <Route path="/" element={<HomePage/>}>
                <Route path="/news/:id/" element={<NewsIdPage/>}/>
            </Route>
            <Route path="/projects/" element={<ProjectsPage/>}/>
            <Route path="/projects/:id/" element={<ProjectsIdPage/>}/>            
            <Route path="/allnews/" element={<AllNewsPage/>}>
                <Route path="/allnews/:id/" element={<NewsIdPage/>}/>
            </Route>
            <Route path="/about/" element={<AboutPage/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>   
    );
}
 
export default AppRouter;