import styled from 'styled-components';

const StyledNewsBackground = styled.div`    
    width: 100%;
    max-width: 1920px;

    margin-left: auto;
    margin-right: auto;  
    margin-top: 40px;
    padding-top: 20px;
    background-image: url("/images/news-backgound-wawe1.png") !important;
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    margin-bottom: 40px;

    @media (max-width: 767px) {
        padding-top: 40px;
        margin-top: 0;
        background-image: none !important;
        margin-bottom: 10px;
    }
`

const NewsBackground = (props) => {
    return <StyledNewsBackground {...props}/>
}
 
export default NewsBackground;
