import styled from "styled-components"


const AboutAddressesWrapper = styled.div`
    width: auto;
    max-width: 1024px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: 1fr 5px 1fr;
    grid-column-gap: 40px;
    font-size: 1.25rem;

    @media (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }    
`

const AboutAddressesItem1 = styled.div`
    justify-self: end;

    > :first-child {
        font-weight: 400;
        margin-bottom: 20px;
    }

    @media (max-width: 767px) {
        width: 360px;
        margin-bottom: 40px;
    }  
`

const AboutAddressesItem2 = styled.div`
    > :first-child {
        font-weight: 400;
        margin-bottom: 20px;
    }
    @media (max-width: 767px) {
        width: 360px;
    }  
`

const AboutAddressesImg = styled.img`
    @media (max-width: 767px) {
        display: none;
    }
`

const AboutAddresses = () => {
    return ( 
            <AboutAddressesWrapper>
                <AboutAddressesItem1>
                    <p>
                        England
                    </p>
                    <p>
                        16 Carlisle St, Soho, London, W1D 3BT England<br/>
                        +44 (0) 207 7577467
                    </p>
                </AboutAddressesItem1>
                <AboutAddressesImg src="/images/about_vertical_line.png" />
                <AboutAddressesItem2>
                    <p>
                        Cyprus
                    </p>
                    <p>
                        6 Rodou Agioi Omologites, Nicosia, 1086  Cyprus<br/>
                        +357 96 821057
                    </p>
                </AboutAddressesItem2>
            </AboutAddressesWrapper>
        );

}
 
export default AboutAddresses;