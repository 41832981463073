import styled from 'styled-components';

const StyledNewsItemDescription = styled.p`    
    font-weight: 300;
    font-size: 1rem; 
    color: #383838;
`

const NewsItemDescription = (props) => {
    return <StyledNewsItemDescription {...props}/>
}
 
export default NewsItemDescription;
