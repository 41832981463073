import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/lazy";
import "../../styles/WideSlider.css";
// import required modules
import { Autoplay, EffectCoverflow, Lazy } from "swiper";
import { motion } from "framer-motion";
import {animationOnce} from '../../index';
import styled from 'styled-components';
import { baseURL } from '../../index';


const WideSliderContainer = styled(motion.div)`    
    margin-top: 40px;
    
    @media (max-width: 767px) {
        margin-top: 30px;
        margin-bottom: -20px;
    }       
`


const WideSlider = (props) => {

    return (
        <WideSliderContainer
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: animationOnce }}
            transition={{ duration: 0.5 }}
        >           
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 400,
                    modifier: 1,
                    slideShadows: true,
                }}
                modules={[EffectCoverflow, Autoplay, Lazy]}
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                }}
                preloadImages={false}
                lazy={true}
                className="wide-slider-swiper"
            >

                {props.slidesList.map((slide) =>
                    <SwiperSlide key={slide}>
                        {/* <img src={baseURL + slide} /> */}
                        <img src={slide} />
                    </SwiperSlide>
                )}

            </Swiper>
        </WideSliderContainer>

    );
}
 
export default WideSlider;