import React from 'react';
import styled from 'styled-components';
import { motion } from "framer-motion";
import {animationOnce} from '../../index';
import AnimationLeft  from '../Animation/AnimationLeft';
import AnimationRight from '../Animation/AnimationRight';


const ProjectDescriptionBackground = styled.div`
    width: auto;
    max-width: 1920px;
    height: auto;
    margin-left: auto;
    margin-right: auto;    
    background-image: url("/images/about_home_background.png") !important;    
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center; 
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 767px) {     
        background-image: none !important;
    }
`

const ProjectDescriptionContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1.5fr 4px 1fr;
    grid-gap: 50px;
    align-items: start;
    padding-top: 200px;

    @media (max-width: 1279px) {
        padding-top: 160px;
    }
    @media (max-width: 1219px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (max-width: 1023px) {
        grid-template-columns: repeat(1, 1fr);        
        max-width: 600px;
    }
    @media (max-width: 767px) {
        padding-top: 0;
    }    

`

const ProjectVerticalLine = styled.img`
    @media (max-width: 1023px) {
        display: none;
    }
`

const ProjectText = styled.div`
    font-size: 1.25rem;
    line-height: 2rem;
    text-align: justify;
    letter-spacing: 2px;

    p {
        padding-bottom: 5px;
    }
`
const ProjectCharacteristicsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
`
const ProjectCharacteristicsLable = styled.div`
    font-size: 1.25rem;
    font-weight: 500;

    @media (max-width: 767px) {
        font-size: 1rem;
    }
`

const ProjectCharacteristicsText = styled.div`
    font-size: 1.25rem;

    @media (max-width: 767px) {
        font-size: 1rem;
    }
`

const ProjectDescriptionFooter = styled.div`
    width: auto;
    max-width: 1920px;
    height: auto;
    margin-left: auto;
    margin-right: auto;    
    background-image: url("/images/about_home_footer_wave.png") !important;    
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center; 
    min-height: 131px;
    
    @media (max-width: 767px) {
        display: none;
    }
`


const ProjectDescription = (props) => {
    return(
        <>
        <ProjectDescriptionBackground>
            <ProjectDescriptionContainer>
                <AnimationLeft>
                    <ProjectText dangerouslySetInnerHTML={{__html: props.projectItem.description}} />
                </AnimationLeft>

                <ProjectVerticalLine src='/images/project_vertical_line.png' />

                <AnimationRight>
                    <ProjectCharacteristicsContainer>
                        <ProjectCharacteristicsLable>CATEGORY:</ProjectCharacteristicsLable>
                        <ProjectCharacteristicsText>{props.projectItem.category}</ProjectCharacteristicsText>
                        <ProjectCharacteristicsLable>GENRE:</ProjectCharacteristicsLable>
                        <ProjectCharacteristicsText>{props.projectItem.genre}</ProjectCharacteristicsText>
                        <ProjectCharacteristicsLable>TARGET AUDIENCE:</ProjectCharacteristicsLable>
                        <ProjectCharacteristicsText>{props.projectItem.audience}</ProjectCharacteristicsText>
                        <ProjectCharacteristicsLable>RUNNING TIME:</ProjectCharacteristicsLable>
                        <ProjectCharacteristicsText>{props.projectItem.lastence}</ProjectCharacteristicsText>
                        <ProjectCharacteristicsLable>STATUS:</ProjectCharacteristicsLable>
                        <ProjectCharacteristicsText>{props.projectItem.status}</ProjectCharacteristicsText>
                        <ProjectCharacteristicsLable>RELEASE DATE:</ProjectCharacteristicsLable>
                        <ProjectCharacteristicsText>{props.projectItem.release}</ProjectCharacteristicsText>
                    </ProjectCharacteristicsContainer>
                </AnimationRight>
            </ProjectDescriptionContainer>
        </ProjectDescriptionBackground>
        <ProjectDescriptionFooter/>
        </>
    );
}

export default ProjectDescription;