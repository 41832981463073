import React from 'react';
import {
    Link
} from "react-router-dom";


const HeaderLogo = () => {

    return (
        <Link to="/">
            <img src="/images/logo.png" />
        </Link>
    );
}
 
export default HeaderLogo;
