import React from 'react';
import styled, {css} from 'styled-components';

const StyledHeaderWrapper = styled.div`
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 170px 1fr 1px;
    grid-gap: 10px;
    align-items: center;
    /* justify-items: end; */
    height: 80px;


    @media(max-width:1300px) {
        padding-left: 30px;
    }    

    ${props => props.debug && css`
        border: 1px solid red;

        & > * {
            background-color: burlywood;
        }
    `}

`

const HeaderWrapper = (props) => {
    return <StyledHeaderWrapper {...props}/>
}
 
export default HeaderWrapper;
