
import React, { useEffect } from 'react';
import styled, {css} from 'styled-components';
import Loader from '../Loader/Loader';


const StyledModal = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    background: rgb(0, 0, 0, 0.85);
    z-index: 2;
    height: 100%;
    width: 100%;
`

const StyledModalContent = styled.div`
    position: relative;
    background: ${props => props.background || "none"};
    border-radius: 6px;    
    width:  ${props => props.width || "90%"};
    height: ${props => props.height || "auto"};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${props => props.marginTop || "0"};
    padding: ${props => props.padding || "5px"};    
    max-width: 95%;
    max-height: 100%;
    overflow: hidden;
    
    ${props => props.enableOverflow && css`
        overflow: auto;
    `}

    @keyframes zoom {
        from {transform:scale(0.3)}
        to {transform:scale(1)}
    }

    animation-name: zoom;
    animation-duration: 0.6s;
`

const StyledCloseBtnWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 20px;
    display: block;
    text-align: right;
    z-index: 3;
    /* блок с кнопкой закрытия модального окна находится на переднем плане и перекрывает все объекты,
     ограничил его по высоте 60 пикселей, чтобы он не блокировал контролы слайдера*/
    height: 60px;

    @media (max-width: 767px) {
        height: 45px;
    }        
`

const StyledCloseBtn = styled.div`
    color: #aaa;
    font-size: 3em;
    font-weight: bold;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    
    &:hover,
    &:focus {
        color: orange;
        text-decoration: none;
        cursor: pointer;
    }
    @media (max-width: 767px) {
        font-size: 2em;
        width: 40px;
        height: 40px;        
    }    
`

const Modal = ({children, closeBtnCallback, contentIsLoading, ...props}) => {    

    // Вешаем слушатель нажатия клавиш, чтоб закрыть модальное окно при нажатии escape
    useEffect(() => {
        const handleKeyPress = (e) => {
            if(e.key === 'Escape'){
                closeBtnCallback();
            }
        }

        window.addEventListener('keydown', handleKeyPress)
        return () => window.removeEventListener('keydown', handleKeyPress)
    },[])

    // При появлении модульного окна выключаем фоновый скрол
    // При пропадании модульного окна включаем скрол (ф-ия cleanup возвращается return)
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.getElementsByTagName('html')[0].style.overflow = "hidden";

        return () => {
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = 'auto';
            document.getElementsByTagName('html')[0].style.overflowX = "hidden";
            document.getElementsByTagName('html')[0].style.overflowY = "auto";
        }
    },[])

    return ( 
        // При клике в пустую область вызывать closeBtnCallback
        // в StyledModalContent делаем отмену обработки клика, чтобы окно не закрывалось при клике на контенте
        <StyledModal {...props} onClick={closeBtnCallback}>
            {/* Если идет загрузка данных, показываем анимацию, если нет, то контент модального окна */}
            {contentIsLoading
                ? <Loader marginTop={"100px"} />
                :
                <StyledModalContent {...props} onClick={e => e.stopPropagation()}>
                    <StyledCloseBtnWrapper {...props}>
                        <StyledCloseBtn onClick={closeBtnCallback}>
                            &times;
                        </StyledCloseBtn>
                    </StyledCloseBtnWrapper>
                    
                    {children}
                </StyledModalContent>                
            }
        </StyledModal>
    );
}

export default Modal;
