import styled from 'styled-components';
import SectionLable from '../SectionLable/SectionLable';
import ProjectsSlider from './ProjectsSlider';
import SeeAllItemsLink from '../SeeAllItemsLink';


const ProjectListContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 80px; Вернуть, когда появится видео на главной */
    margin-top: 40px;
    background-color: #1b1b1b;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: 1219px) {
        padding-left: 10px;
        padding-right: 10px;
    } 
    @media (max-width: 767px) {
        padding-top: 60px;
        padding-bottom: 60px;
    }  
`

const ProjectsListHome = (props) => {
    return (
        <ProjectListContainer>
            <SectionLable id={'work'} textColor="#f9f9f9">
                PROJECTS
            </SectionLable>
            <ProjectsSlider />
            <SeeAllItemsLink to="/projects/">
                ALL PROJECTS
            </SeeAllItemsLink>
        </ProjectListContainer>
    );
}

export default ProjectsListHome;
