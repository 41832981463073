import React from 'react';
import styled, {css} from 'styled-components';

const StyledSectionLableContainer = styled.div`    
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: ${props => props.marginTop || "0"};
    margin-bottom: ${props => props.marginBottom || "0"};
`

const SectionLableContainer = (props) => {
    return <StyledSectionLableContainer {...props}/>
}
 
export default SectionLableContainer;

