import styled from 'styled-components';

const StyledMFAButton = styled.div`    
    border: none;
    border-radius: 30px;
    color: #f9f9f9;
    cursor: pointer;
    background-color: #fc6435;
    font-weight: 300;
    font-size: 1.25rem;
    display: inline-block;
    min-width: 260px;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

`

const MFAButton = (props) => {
    return <StyledMFAButton {...props}/>
}
 
export default MFAButton;
