import styled from 'styled-components';

const StyledNewsContainer = styled.div`    
    width: 100%;
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 80px;   

    @media (max-width: 1259px) {
        justify-content: space-around;
    }
    @media (max-width: 767px) {
        padding-top: 40px; 
    }       

`

const NewsContainer = (props) => {
    return <StyledNewsContainer {...props}/>
}
 
export default NewsContainer;
