import styled from "styled-components";
import AnimationRight from "../Animation/AnimationRight";
import AnimationLeft from "../Animation/AnimationLeft";


const FooterOrangeBackground = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fc6435;
    
`

const FooterOrangeWrapper = styled.div`
    width: 100%;
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
    min-height: 300px;
    padding: 40px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    @media(max-width:767px) {
        grid-template-columns: 1fr;
    }   
`

const FooterOrangeContacts = styled.div`
    justify-self: center;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;    

    h1 {
        font-size: 2.5rem;
        color: white;
        font-weight: 400;
    }
    p {
        font-size: 1.25rem;
        font-weight: 400;
    }
    a{
        font-size: 1.25rem;
        font-weight: 400;
    }
    a:hover {
        color: white;
    }
`

const FooterOrangeImage = styled.img`
    align-self: center;
    justify-self: center;

    @media(max-width:767px) {
        display: none;
    }   
`

const FooterOrange = () => {
    return ( 
        <FooterOrangeBackground id="ContactUs">
            <FooterOrangeWrapper>                
                <FooterOrangeContacts>
                    <h1 >
                        CONTACT US!
                    </h1>
                    <p>
                        Please, email all your inquiries to us. <br/>
                        We will be happy to answer them for you.
                    </p>
                    <a href="mailto:info@magicframeanimation.com">
                        info@magicframeanimation.com
                    </a>
                </FooterOrangeContacts>
                <FooterOrangeImage src="/images/footer_icon.png" />
            </FooterOrangeWrapper>
        </FooterOrangeBackground>
     );
}

export default FooterOrange;