import axios from 'axios';

export default class ProjectsService {
    static async getProjects(limit = 4, offset = 0) {
        const response = await axios.get('/api/v1/projects/', {
            params: {
                limit: limit,
                offset: offset
            }
        })
        return response
    }

    static async getProjectItem(id) {
        const response = await axios.get(`/api/v1/projects/${id}`)
        return response
    }    

    static async getProjectScreens(id) {
        const response = await axios.get(`/api/v1/projects/${id}/screens/`)
        return response
    }    
}
