import styled from "styled-components";


const AllNewsBackgroundStyled = styled.div`
    background-color: #1b1b1b;
    padding-top: 40px;
    padding-bottom: 60px;
`

const AllNewsBackground = (props) => {
    return ( 
        <AllNewsBackgroundStyled {...props} />
     );
}
 
export default AllNewsBackground;
