import React from 'react';
import styled from 'styled-components';
import { useFetching } from "../../hooks/useFetching";
import ProjectsService from '../../API/ProjectsService';
import { useState, useEffect } from "react";
import { baseURL } from '../..';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../Loader/Loader';
import AnimationLeft from '../Animation/AnimationLeft';
import ProjectsItem from './ProjectsItem';
import SectionLable from '../SectionLable/SectionLable';


const ProjectsListBackgorund = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1b;

    padding-top: 40px;
    padding-bottom: 40px;
`

const ProjectsListWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    justify-items: center;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    
    @media (max-width: 1023px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;
        padding-left: 40px;
        padding-right: 40px;         
    }
    @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        padding-left: 20px;
        padding-right: 20px;        
    }

`


const ProjectsList = (props) => {
    const [projectsList, setProjectsList] = useState([]);

    const [fetchProjects, isProjectsLoading, ProjectsLoadingError] = useFetching(async (projectsLimit) => {
        const response = await ProjectsService.getProjects(projectsLimit);        
        setProjectsList(response.data.results);        
    })

    useEffect(() => {
        fetchProjects(props.numProjectsToLoad);
    }, [])

    return(
        <ProjectsListBackgorund>
            {/* Если происходит подгрузка проектов, показываем анимацию */}
            {isProjectsLoading &&
                <Loader marginBottom={"30px"} marginTop={"30px"} />
            }

            <SectionLable textColor="#f9f9f9">
                PROJECTS
            </SectionLable>

            <ProjectsListWrapper>
                    {projectsList.map( (item) =>
                        <AnimationLeft
                            key={item.pk}  
                        >
                            <ProjectsItem
                                category={item.category}
                                title={item.title}
                                status={item.status}
                                poster={baseURL + item.poster_url}
                                projectID={item.pk}
                                key={item.pk}
                            />
                        </AnimationLeft>
                    )}
            </ProjectsListWrapper>
        </ProjectsListBackgorund>
    );
}
 
export default ProjectsList;
