import styled from "styled-components";
import SectionLable from "../SectionLable/SectionLable";
import AnimationRight from "../Animation/AnimationRight";
import AnimationFadeOut from "../Animation/AnimationLeft";


const AboutUpperBlockBackground = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1b;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #f9f9f9;
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 30px;
    
`

const AboutUpperBlockWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 80px;

    @media(max-width:1024px) {
        column-gap: 20px;
    }
    @media(max-width:767px) {
        grid-template-columns: 1fr;
        row-gap: 30px;
    }     
`

const AboutUpperBlockItem1 = styled.div`  
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-around;

    @media(max-width:767px) {
        height: 200px;
    }    
`

const AboutUpperBlockItem2 = styled.div`
    /* Выбираем первый тег P */
    > :first-child {
        margin-bottom: 10px;
    }
`

const AboutUpperBlockLogo = styled.img`
    align-self: center;
`


const AboutUpperBlock = () => {
    return (
        <AboutUpperBlockBackground>
            <AboutUpperBlockWrapper>
                <AboutUpperBlockItem1>
                    <SectionLable textColor="#fc6435">
                        WHO WE ARE
                    </SectionLable>
                    <AboutUpperBlockLogo src="/images/about_us_logo.png" />
                </AboutUpperBlockItem1>
                <AnimationRight>
                <AboutUpperBlockItem2>                    
                    <p>Magic Frame Animation is an international full-cycle animation studio producing visually stunning stories across film, television and digital platforms.</p>
                    <p>Our logo, an orange whale, is a symbol not only of our focus on quality animation and world-leading projects, it also represents the creative, safe and flexible working environment that we have built for our team and partners. We like to think this is a nod to how it feels when sailing on the water inside a reliable ship!</p>                    
                </AboutUpperBlockItem2>                
                </AnimationRight>
            </AboutUpperBlockWrapper>
        </AboutUpperBlockBackground>
    );
}
 
export default AboutUpperBlock;