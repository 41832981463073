import axios from 'axios';

export default class NewsService {
    static async getNews(limit = 4, offset = 0) {
        const response = await axios.get('/api/v1/news/', {
            params: {
                limit: limit,
                offset: offset
            }
        })
        return response
    }

    static async getNewsID(id) {
        const response = await axios.get(`/api/v1/news/${id}`)
        return response
    }    

}
