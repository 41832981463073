import React from 'react'; 
import ReactDOM from 'react-dom/client'; 
import App from './App';
import { ThemeProvider} from 'styled-components';
import { GlobalStyle, theme} from './components/Layout/GlobalStyle';
import { BrowserRouter } from "react-router-dom";
import axios from 'axios';
import MFAContextProvider from './hoc/MFAContextProvider';

export const animationOnce = true;

export const baseURL = "https://magicframeanimation.com"
//export const baseURL = "http://10.10.10.10:8000"

axios.defaults.baseURL = baseURL;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ThemeProvider theme={theme}>
        <GlobalStyle/>
        <MFAContextProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </MFAContextProvider>
    </ThemeProvider>
);
