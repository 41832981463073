import styled from 'styled-components';

const StyledNewsItemText = styled.div`    
    font-weight: 400;
    font-size: 1.2rem; 
    text-align: justify;
    
    & > p {
        padding-bottom: 15px;
    }
`

const NewsItemText = (props) => {
    return <StyledNewsItemText {...props}/>
}
 
export default NewsItemText;
