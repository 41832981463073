import React from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/Slider.css";
// import required modules
import { Autoplay, Navigation } from "swiper";

import ProjectsItem from './ProjectsItem';
import { useFetching } from "../../hooks/useFetching";
import ProjectsService from '../../API/ProjectsService';
import { useState, useEffect } from "react";
import { baseURL } from '../..';
import Loader from '../Loader/Loader';


const ProjectsSliderContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 80px;

    @media (max-width: 1219px) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media (max-width: 767px) {
        margin-top: 50px;
        margin-bottom: 50px;
    }    
`

const ProjectsSlider = (props) => {
    const [projectsList, setProjectsList] = useState([]);

    // Убирает из списка проектов в слайдере текущий проект
    const filterProjects = (projList, curProjID) => {
        let filteredProjList = []
        for (const i in projList) {            
            if ( projList[i]['pk'] !== parseInt(curProjID)) {
                filteredProjList.push(projList[i]);
            }
        }
        return filteredProjList;
    }

    const [fetchProjects, isProjectsLoading, ProjectsLoadingError] = useFetching(async (projectsLimit) => {
        const response = await ProjectsService.getProjects(projectsLimit);
        const filteredProjList = filterProjects(response.data.results, props.currentProject);
        setProjectsList(filteredProjList);
    })

    useEffect(() => {
        fetchProjects(20);
    }, [])


    return(
        <ProjectsSliderContainer>
                <Swiper
                    breakpoints={{
                        // если разрешение больше 280
                        280: {
                            slidesPerView: 1,
                        },
                        520: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                        },                        
                        1366: {
                            slidesPerView: 5,
                        },                    
                    }}
                    spaceBetween={15}
                    slidesPerGroup={1}            
                    loop={true}
                    loopFillGroupWithBlank={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: true,
                    }}            
                    // navigation={true}
                    modules={[Navigation, Autoplay]}
                    className="slider-swiper"
                >

                    {projectsList.map((item) =>  
                        <SwiperSlide key={item.pk}>
                                <ProjectsItem 
                                    poster={baseURL + item.poster_url} 
                                    title={item.title}
                                    category={item.category}
                                    projectID={item.pk}
                                />
                        </SwiperSlide>                        
                    )}
          
                </Swiper>

            {/* Если происходит подгрузка проектов, показываем анимацию */}
            {isProjectsLoading &&
                <Loader marginBottom={"30px"} />
            }                
        </ProjectsSliderContainer>
    );
}

export default ProjectsSlider;