import React from 'react';
import styled, {css} from 'styled-components';
import SectionLableContainer from './SectionLableContainer';
import AnimationRight from '../Animation/AnimationRight';


const StyledSectionLable = styled.div`    
    font-size: 2.5rem;
    font-weight: 400;
    text-align: center;
    /* letter-spacing: 5px; */
    margin-top: ${props => props.marginTop || "0"};
    margin-bottom: ${props => props.marginBottom || "0"};
    padding-top: ${props => props.paddingTop || "0"};
    padding-bottom: ${props => props.paddingBottom || "0"};
    color: ${props => props.textColor || "#383838"};
    text-transform: uppercase;
`

const SectionLable = (props) => {
    return (
        <AnimationRight>
            <SectionLableContainer {...props}>
                <StyledSectionLable {...props}/> 
            </SectionLableContainer>
        </AnimationRight>
    );
}
 
export default SectionLable;

