import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MFAButton from './MFAButton';


const ButtonContainer = styled.div`
    text-align: center;    

    /* @media (max-width: 919px) {
        margin-top: 40px;
    }         */
`


const SeeAllItemsLink = (props) => {
    return (
        <ButtonContainer>
            <Link to={props.to}>
                <MFAButton >{props.children}</MFAButton>
            </Link>
        </ButtonContainer>
    )
}

export default SeeAllItemsLink;
