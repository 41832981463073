import styled from 'styled-components';

const StyledNewsItemLink = styled.div`    
    color: #fc6435;
    font-size: 1rem;
    font-weight: 300; 
    padding-top: 15px;    
    /* margin-top: auto; переместить вниз */
    align-self: flex-end;
`

const NewsItemLink = (props) => {
    return (
        <StyledNewsItemLink {...props}>
            read more <img src='/images/arrow.png' />
        </StyledNewsItemLink>
    );
}
 
export default NewsItemLink;
