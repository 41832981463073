import styled from 'styled-components';


const StyledAboutUpperLine = styled.div`    
    width: auto;
    max-width: 1920px;
    height: auto;
    min-height: 176px;
    margin-left: auto;
    margin-right: auto;    
    background-image: url("/images/about_home_background.png") !important;    
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    margin-top: 30px;

    @media (max-width: 767px) {
        /* background-image: url("/images/about_home_background_767.png") !important; */
        /* background-image: none !important;
        min-height: 0;
        margin-top: 30px; */
        display: none;
    }
`

const StyledAboutBottomLine = styled.div`    
    width: auto;
    max-width: 1920px;
    height: auto;
    min-height: 131px;
    margin-left: auto;
    margin-right: auto;    
    background-image: url("/images/about_home_footer_wave.png") !important;    
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    margin-bottom: 40px;

    @media (max-width: 767px) {
        display: none;
    }
`

export const AboutUpperLine = () => {
    return ( 
        <StyledAboutUpperLine />
     );
}
 
export const AboutBottomLine = () => {
    return ( 
        <StyledAboutBottomLine />
     );
}
