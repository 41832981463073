import styled from 'styled-components';

const StyledNewsDate = styled.p`    
    color: #FB8005;
    font-size: 0.9rem;
    font-weight: 500; 
    padding-bottom: 10px;    
`

const NewsDate = (props) => {
    return (
        <StyledNewsDate {...props}/>
    );
}
 
export default NewsDate;
