import React from 'react';
import styled from 'styled-components';


const StyledHeaderMenu = styled.div`    
    font-size: 1.56rem;    
    margin: 0;
    padding-left: 0;
    display: none;
    /* Почему-то не работает     */
    /* word-spacing: 60px; */
    justify-self: end;

    ul {
        list-style: none;
    }
    ul > :nth-child(1), ul > :nth-child(2), ul > :nth-child(3), ul > :nth-child(4){
        padding-right: 60px;
    }
    ul li {
        display: inline;        
    }

    a {
        color: white;
    }
    a:hover {
        color: #fc6435;
    }
    /* Класс active на ссылки в меню добавляется компонентом NavLink при клике */
    a.active {
        color: #fc6435;
    }

    /* Последняя ссылка - кнопка */
    ul > :nth-child(5){
        padding: 5px 30px;
        background-color: #fc6435;
        border-radius: 20px;
        margin-right: 20px;
        
        /* чтобы при наведении цвет текста не менялся */
        a:hover {
            color: white;
        }
    }

    @media(max-width:600px) {
        .menu {
            font-size: 0.8rem;
            word-spacing: 5px;
            font-weight: 400;
        }
    }
    
    @media(min-width:1120px) {
        display: block;
    }    
`

const HeaderMenu = (props) => {
    return <StyledHeaderMenu {...props}/>
}
 
export default HeaderMenu;
