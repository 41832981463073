import styled from 'styled-components';

const StyledNewsItemHeader = styled.p`    
    font-weight: 400;
    font-size: 1rem;
    color: #1b1b1b;
    margin-bottom: 10px;
`

const NewsItemHeader = (props) => {
    return <StyledNewsItemHeader {...props}/>
}
 
export default NewsItemHeader;
