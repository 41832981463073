import Modal from "../components/Modal/Modal";
import NewsModalContent from "../components/News/NewsItem/NewsModalContent";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NewsService from "../API/NewsService";
import { useFetching } from "../hooks/useFetching";


function NewsIdPage() {
    const navigate = useNavigate();
    const params = useParams();
    const [newsItem, setNewsItem] = useState({});

    const [fetchNewsID, isNewsIDLoading, newsIDError] = useFetching(async (newsId) => {
        const response = await NewsService.getNewsID(newsId);
        setNewsItem(response.data);
    })

    useEffect(() => {
        fetchNewsID(params.id);
    }, [])

    return (
        <>
            <Modal 
                background={"white"} 
                width={"1200px"} 
                marginTop={"20px"}
                padding={"20px"}                
                // -1 значит вернуться назад
                closeBtnCallback={() => navigate(-1)}
                enableOverflow
                contentIsLoading={isNewsIDLoading}
            >
                    <NewsModalContent item={newsItem}/>
            </Modal>
        </>
    );
  }
  
  export default NewsIdPage;
  