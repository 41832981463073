import styled from 'styled-components';

const StyledProjectSingleScreen = styled.img`    
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    display: block;
    width: 100%;
    max-width: 1200px;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
`

const ProjectSingleScreen = (props) => {
    return <StyledProjectSingleScreen {...props}/>
}
 
export default ProjectSingleScreen;
