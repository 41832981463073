import styled from 'styled-components';
import SectionLable from '../SectionLable/SectionLable';
import AnimationLeft from '../Animation/AnimationLeft';
import AnimationRight from '../Animation/AnimationRight';


const AboutHomeBackground = styled.div`
    width: auto;
    max-width: 1920px;
    height: auto;
    margin-left: auto;
    margin-right: auto;    
    background-image: url("/images/about_home_background.png") !important;    
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: auto;

    @media (min-width: 768px) {
        margin-top: 40px;
    }
    @media (max-width: 767px) {
        margin-top: 0;
        background-image: none !important;
    }
`
const AboutHomeContainer = styled.div`
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 120px;

    @media (max-width: 767px) {
        padding-top: 50px;
    }    
`

const AboutTextWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 10px;

    @media (max-width: 1100px) {
        justify-content: center;
    }   
`
const AboutItem = styled.div`
    width: 100%;
    max-width: 520px;
    min-width: 360px;
    margin-bottom: 20px;    

    img {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    p {
        line-height: 35px;
        font-size: 1.2rem;
    }
`

const AboutHomeFooterBackground = styled.div`
    width: auto;
    max-width: 1920px;
    height: auto;
    margin-left: auto;
    margin-right: auto;    
    background-image: url("/images/about_home_footer_wave.png") !important;    
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    min-height: 131px;
    
    @media (max-width: 767px) {
        display: none;
    }

`

const AboutHome = () => {
return(
    <>
    <AboutHomeBackground id={'about'}>            
        <AboutHomeContainer>                                
            <SectionLable>
                ABOUT US
            </SectionLable>
            <AboutTextWrapper>
                <AnimationLeft>
                    <AboutItem>
                        <img src='/images/gear.png' />
                        <p>We are Magic Frame Animation, a full-cycle animation studio producing visually stunning stories for film, television and digital platforms.</p>
                    </AboutItem>
                </AnimationLeft>
                <AnimationRight>
                    <AboutItem>
                        <img src='/images/lamp.png' />
                        <p>We are an international team made up of experienced artists and skilled technicians operating across the globe, under a high-end production pipeline.</p>
                    </AboutItem>
                </AnimationRight>
            </AboutTextWrapper>            
        </AboutHomeContainer>
    </AboutHomeBackground>
    
    <AboutHomeFooterBackground />    
    </>
);
}

export default AboutHome;
