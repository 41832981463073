import styled from 'styled-components';
import AnimationFadeOut from '../Animation/AnimationLeft';

const Map = styled.img`
    width: 100%;
    max-width: 1400px;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: -50px;

    @media (max-width: 767px) {
        margin-top: 40px;
    }    
`

const AboutMap = () => {
    return(
        <AnimationFadeOut>
            <Map src='/images/map.png' />
        </AnimationFadeOut>
    );
}
 
export default AboutMap;


