import { motion } from "framer-motion";
import { animationOnce } from '../..';


const AnimationLeft = (props) => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.5, x: -200 }}
            whileInView={{ opacity: 1, scale: 1, x: 0 }}
            viewport={{ once: animationOnce }}
            transition={{ duration: 0.5 }}
        >
            {props.children}
        </motion.div>
    )
}
 
export default AnimationLeft;
