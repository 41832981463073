import React from 'react';
import styled from 'styled-components';
import { useFetching } from '../../hooks/useFetching';
import NewsService from '../../API/NewsService';
import { MFAContext } from '../../hoc/MFAContextProvider';
import Loader from '../Loader/Loader';
import MFAButton from '../MFAButton';
import { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import AnimationLeft  from '../Animation/AnimationLeft';
import AnimationRight from '../Animation/AnimationRight';


const AllNewsContainer = styled.div`    
    width: 100%;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 80px;
    row-gap: 70px;
    justify-items: center;
    color: #f9f9f9;

    @media (max-width: 919px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }    
`

const AllNewsItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 600px;

    p:nth-child(1) {
        margin-top: 20px;        
    }
    p:nth-child(2) {
        margin-top: 40px;
        font-size: 1.25rem;
        letter-spacing: 2px;
        font-weight: 500;
    }
    p:nth-child(3) {
        margin-top: 40px;
        font-size: 1.25rem;
        text-align: justify;
        letter-spacing: 2px;
        line-height: 1.7rem;
        font-weight: 300;
    }
    a {
        font-size: 1rem;
        color: #fc6435;
        letter-spacing: 2px;
        align-self: flex-end;
        margin-top: auto;
        padding-top: 30px;
        padding-right: 40px;
        padding-bottom: 30px;         
    }

    @media (max-width: 7679px) {
        p:nth-child(1) {
            margin-top: 10px;
        }        
        p:nth-child(2) {
            margin-top: 20px;
        }
        p:nth-child(3) {
            margin-top: 20px;
        }
    }   

`

const NewsItemPoster = styled.img`
    width: 100%;
    height: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`

const ButtonContainer = styled.div`
    text-align: center;
    margin-top: 60px;

    @media (max-width: 919px) {
        margin-top: -20px;
    }        
`

const AllNews = (props) => {
    const [newsList, setNewsList] = useState([]);
    const {
        newsLimit, // Сколько новостей грузить
        setNewsLimit, 
        newsToLoad, // По сколько штук за раз грузить новостей
        totalNews, // Сколько всего новостей есть
        setTotalNews
    } = useContext(MFAContext);

    const [fetchNews, isNewsLoading, newsError] = useFetching(async (newsLimit) => {
        const response = await NewsService.getNews(newsLimit);
        setTotalNews(response.data.count);
        setNewsList(response.data.results);
    })

    useEffect(() => {
        fetchNews(newsLimit);
    }, [newsLimit,])    

    const loadMore = () => {
        setNewsLimit(newsLimit + newsToLoad);
    } 

    return (
        <>
        <AllNewsContainer >
            {newsList.map( (item) =>
                <React.Fragment key={item.id}>
                    <AnimationLeft>
                        <AllNewsItem>
                            <NewsItemPoster src={item.poster} />
                        </AllNewsItem>
                    </AnimationLeft>
                    <AnimationRight>
                        <AllNewsItem>
                            <p>{item.date}</p>
                            <p>{item.title}</p>
                            <p>{item.description}</p>
                            <Link to={`/allnews/${item.id}/`}>read more <img src='/images/arrow.png' /></Link>
                        </AllNewsItem>
                    </AnimationRight>
                </React.Fragment>
            )}
        </AllNewsContainer>

        {/* Если происходит подгрузка новостей, показываем анимацию */}
        {isNewsLoading &&
            <Loader marginBottom={"40px"} />
        }

        {/* Если есть еще новости, то показываем кноку */}
        {totalNews > newsLimit &&
            <ButtonContainer>
                <MFAButton onClick={loadMore}>LOAD MORE NEWS</MFAButton>
            </ButtonContainer>
        }      
        </>
    );
}
 
export default AllNews;
