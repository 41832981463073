import styled from 'styled-components';

const StyledNewsItemContainer = styled.div`    
    transition: 0.3s;
    width: 380px;  
    background-color: white;    
    transition: .3s ease-in-out;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;    

    @media (min-width: 1024px) {
        &:hover {
            /* cursor: pointer; */
            transform: scale(1.05);
        }
    } 
    
    @media (max-width: 400px) {
        width: 340px;
    }     
`

const NewsItemContainer = (props) => {
    return(
        <StyledNewsItemContainer {...props}/>
    );
}
 
export default NewsItemContainer;
