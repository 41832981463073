import React from 'react';
import AboutContainer from './AboutContainer';
import SectionLable from '../SectionLable/SectionLable';
import AboutText from './AboutText';
import AboutUpperBlock from './AboutUpperBlock';


const About = (props) => {
    return(
        <AboutContainer>                                
            <AboutText>
                <p>With headquarters in Nicosia and London, we use international servers connected to our creative teams operating from countries including Canada, Brazil, the USA, India, Armenia, Spain, as well as the UK and Cyprus.</p>
            </AboutText>
            <SectionLable>
                OUR OFFICES
            </SectionLable>
        </AboutContainer>
    );
}
 
export default About;
