export const MainPageSlides = [
    "/images/main_page_slides/01.png",
    "/images/main_page_slides/02.png",
    // "/images/main_page_slides/03.png",
    "/images/main_page_slides/04.png",
    "/images/main_page_slides/05.png",
    "/images/main_page_slides/06.png",
    "/images/main_page_slides/08.png",
    "/images/main_page_slides/09.png",
    "/images/main_page_slides/10.png",
    "/images/main_page_slides/11.png",
	"/images/main_page_slides/12.png",
	"/images/main_page_slides/13.png",
]
