import styled from 'styled-components';

const StyledNewsItemImage = styled.img`  
    width: 100%;
    height: auto;    
    margin-bottom: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @media (max-width: 600px) {
    }    
`

const NewsItemImage = (props) => {
    return <StyledNewsItemImage {...props}/>
}
 
export default NewsItemImage;
