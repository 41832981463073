import React from 'react';
import styled from 'styled-components';

const StyledAboutContainer = styled.div`    
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
   
`

const AboutContainer = (props) => {
    return <StyledAboutContainer {...props}/>
}
 
export default AboutContainer;
