import styled from "styled-components";
import ProjectsSlider from "./ProjectsSlider";
import SectionLable from "../SectionLable/SectionLable";
import SeeAllItemsLink from "../SeeAllItemsLink";


const ProjectSlidesBackground = styled.div`
    width: auto;
    max-width: 1920px;
    height: auto;
    margin-left: auto;
    margin-right: auto;    
    background-image: url("/images/pjojects_upper_wawe.png") !important;    
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;    
    margin-top: 40px;
    padding-top: 80px;

    @media (max-width: 767px) {     
        background-image: none !important;        
        padding-top: 0;
    }
`

const ProjectSectionLable = styled(SectionLable)`
    margin-bottom: 80px;

    @media (max-width: 767px) {     
        margin-bottom: -20px;
    }    
`

const ProjectSlidesFooter = styled.div`
    width: auto;
    max-width: 1920px;
    height: auto;
    min-height: 132px;
    margin-left: auto;
    margin-right: auto;    
    background-image: url("/images/projects_bottom_wawe.png") !important;    
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;    
    margin-bottom: 40px;
    margin-top: -20px;
    padding-top: 30px;

    @media (max-width: 767px) {     
        background-image: none !important;
        padding-top: 0;
        min-height: 0;
    }
`

const ProjectSlides = (props) => {
    return (
        <>
        <ProjectSlidesBackground>
            <ProjectSectionLable>
                PROJECTS
            </ProjectSectionLable>          
            <ProjectsSlider currentProject={props.currentProject} />
        </ProjectSlidesBackground>
        <ProjectSlidesFooter>
            <SeeAllItemsLink to="/projects/">
                ALL PROJECTS
            </SeeAllItemsLink>
        </ProjectSlidesFooter>
        </>
     );
}
 
export default ProjectSlides;