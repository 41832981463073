import React from 'react';
import './styles/fonts.css';
import AppRouter from './components/AppRouter';

function App() {
  return (
    <AppRouter />
  );
}

export default App;
