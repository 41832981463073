import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import BurgerMenu from '../components/Header/BurgerMenu';
import About from '../components/About/About';
import AboutMap from '../components/About/AboutMap';
import AboutUpperBlock from '../components/About/AboutUpperBlock';
import { AboutUpperLine, AboutBottomLine } from '../components/About/AboutLines';
import AboutAddresses from '../components/About/AboutAddresses';


function AboutPage() {
    return (
      <>
        <BurgerMenu/>
        <Header/>
        <AboutUpperBlock id={'about'}/>
        <AboutUpperLine />
        <AboutMap/>
        <About/>
        <AboutAddresses />
        <AboutBottomLine />
        <Footer/>
      </>
    );
  }
  
  export default AboutPage;
  