import NewsItemHeader from "./NewsItemHeader";
import NewsItemImage from "./NewsItemImage";
import NewsDate from "./NewsDate";
import NewsItemText from "./NewsItemText";

const NewsContent = (props) => {
    return(
        <>
            <NewsDate>
                {props.item.date}
            </NewsDate>
            <NewsItemHeader>
                {props.item.title}                
            </NewsItemHeader>
            <NewsItemImage src={props.item.poster} />
            <NewsItemText dangerouslySetInnerHTML={{__html: props.item.content}} />
        </>
    );
}
 
export default NewsContent;
