import {createGlobalStyle} from 'styled-components'


export const GlobalStyle = createGlobalStyle`
    ${'' /* @font-face {
        font-family: 'MainFont';
        src: url('/fonts/centurygothic.ttf') format('truetype');
    }
    @font-face {
        font-family: 'BoldFont';
        src: url('/fonts/centurygothic_bold.ttf') format('truetype');
    }     */}

    *, *:before, *:after {
        box-sizing: border-box;    
        margin: 0;
        padding: 0;
    }
    html {
        scroll-behavior: smooth;
        
    }
    body {
        font-family: 'Rubik', sans-serif;
        font-weight: 300;
        padding: 0;
        margin: 0;
    }
    html, body {
        overflow-x: hidden;
    }

    a {
        text-decoration: none;
        color: #000;
    }
    a:hover {
        color: #ff6c05;
    }

`

export const theme = {
    media: {
        phone: '(max-width: 425px)',
        tablet: '(max-width: 768px) and (min-width: 425px)',
    },
}

