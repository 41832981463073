import React from 'react';
import styled from 'styled-components';
import { motion } from "framer-motion";
import { animationOnce } from '../..';

const StyledAboutText = styled.div`
    font-size: 1.35rem;
    text-align: justify;
    line-height: 2.25rem;
    margin-bottom: 40px;

    @media (max-width: 1023px) {
        font-size: 1.3rem;
        line-height: 2rem;
    }
    @media (max-width: 767px) {
        font-size: 1rem;
        line-height: 1.6rem;
    }
`

const AboutText = (props) => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.5, x: -200 }}
            whileInView={{ opacity: 1, scale: 1, x: 0 }}
            viewport={{ once: animationOnce }}
        >
            <StyledAboutText {...props}/>
        </motion.div>
    )
}
 
export default AboutText;
