import React from 'react';
import HeaderWrapper from './HeaderWrapper';
import HeaderContainer from './HeaderContainer';
import HeaderLogo from './HeaderLogo';
import HeaderMenu from './HeaderMenu';
import { Link, NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


const Header = () => {

    return (
        <HeaderContainer>
            <HeaderWrapper>
                <HeaderLogo />
                <HeaderMenu>
                    <ul>
                        <li><NavLink end to="/">Home</NavLink></li>
                        <li><NavLink to="/about/">About us</NavLink></li>
                        <li><NavLink to="/projects/">Projects</NavLink></li>
                        <li><NavLink to="/allnews/">News</NavLink></li>
                        <li><HashLink to="#ContactUs">Contact us</HashLink></li>                        
                    </ul>                    
                </HeaderMenu>                
            </HeaderWrapper>
        </HeaderContainer>
    );
}
 
export default Header;