import NewsItemContainer from "./NewsItemContainer";
import NewsItemHeader from "./NewsItemHeader";
import NewsItemImage from "./NewsItemImage";
import NewsItemDescription from "./NewsItemDescription";
import NewsItemLink from "./NewsItemLink";
import { Link } from "react-router-dom";


const NewsItem = (props) => {
    return(
        <Link to={`/news/${props.item.id}/`}>
            <NewsItemContainer>
                <NewsItemImage src={props.item.poster} />
                <NewsItemHeader id={`news${props.item.id}`}>
                    {props.item.title}                
                </NewsItemHeader>                
                <NewsItemDescription>
                    {props.item.description}
                </NewsItemDescription>                
                <NewsItemLink />                
            </NewsItemContainer>
        </Link>
    );
}
 
export default NewsItem;
