import styled from "styled-components";


const NewsFooterBackground = styled.div`
width: auto;
max-width: 1920px;
height: auto;
margin-left: auto;
margin-right: auto;    
background-image: url("/images/news-backgound-wawe2.png") !important;    
background-repeat: no-repeat;
background-size: auto;
background-position-x: center;
min-height: 140px;
margin-bottom: 40px;

@media (max-width: 767px) {
        margin-bottom: 40px;
        min-height: 0;
        background-image: none !important;
}
`

const NewsFooter = () => {
    return ( 
        <NewsFooterBackground/>
     );
}
 
export default NewsFooter;