import React from 'react';
import styled, {css} from 'styled-components';

const StyledHeaderContainer = styled.div`    
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1b;

    ${props => props.debug && css`
        background-color: bisque;
    `}    
`

const HeaderContainer = (props) => {
    return <StyledHeaderContainer {...props}/>
}
 
export default HeaderContainer;
