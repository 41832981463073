import styled from "styled-components";
import { css } from "styled-components";
import { motion } from 'framer-motion';
import { animationOnce } from "../..";
import Modal from '../Modal/Modal';
import ImageGallery from 'react-image-gallery';
import "../../styles/Galery.css";
import { useState } from "react";


const ProjectScreensBackground = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1920px;
    background-color: #1b1b1b;
    padding: 100px 70px;
    margin-top: 80px;

    @media (max-width: 1599px) {
        padding: 80px 50px;
    } 
    @media (max-width: 1279px) {
        padding: 40px 20px;
        margin-top: 40px;
    }
    @media (max-width: 1023px) {
        padding: 40px 10px;
    }
    @media (max-width: 767px) {
        padding: 20px 10px;
        margin-top: 40px;
    }    
`

const ProjectScreen = styled(motion.img)`
    width: 100%;
    height: auto;
    display: block;
    cursor: pointer;
`

const ProjectScreensWrapper = styled(motion.div)`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1776px;    
    display: grid;
    grid-template-columns: 4fr 2fr 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 40px;
    

    img:nth-child(1) {
        grid-row: 1 / 3;        
    }
    img:nth-child(2) {
        grid-row: 1 / 3;
    } 
    img:nth-child(5) {
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }

    /* При оладки включаем бордюры */
    ${props => props.debug && css`
        border: 1px solid yellow;

        & img {
            border: 1px solid green;
        }
    `}

    @media (max-width: 1599px) {
        grid-gap: 30px;
    }
    @media (max-width: 1200px) {
        grid-gap: 25px;
    }    
    @media (max-width: 1023px) {
        grid-gap: 20px;
    }
    @media (max-width: 767px) {
        grid-gap: 8px;
    }
`

const ProjectScreens = (props) => {
    const [screensIsOpen, setScreensIsOpen] = useState(false);
    const [currentScreen, setCurrentScreen] = useState(0);

    // Ф-ия выполняется при клике на скрине
    const showScreens = (id) => {
        setCurrentScreen(id - 1);
        setScreensIsOpen(true);        
    } 

    // Анимация группы скринов, обратить внимание, что контейнер со скринами ProjectScreensWrapper
    // и сам скрин ProjectScreen выше унаследованы от motion.div и motion.img соответственно

    const animationContainer = {
        hidden: {scale: 0.2, opacity: 0, },          // анимация корневого контейнера

        visible: {
            scale: 1,
            opacity: 1,

            transition: {
                delayChildren: 0.6,    // время до начала анимации группы дочерних элементов
                staggerChildren: 0.3,  // интервал между анимациями дочерних элементов
                duration: 2,           // время анимации, но всей группы целиком, а не одного элемента!
                                       // работает если только у корневого элемента тоже есть анимация?
            }
        }
      };
      
    const animationItem = {
        hidden: { opacity: 0, scale: 0.2, x: -200 },
        visible: { opacity: 1, scale: 1, x: 0 }
    };

    return ( 
        <>
        {/* MODAL */}
        {screensIsOpen &&
            <Modal
                closeBtnCallback={() => setScreensIsOpen(false)}
            >
                {props.projectScreens &&
                    <ImageGallery 
                        items={props.projectScreens} 
                        startIndex={currentScreen} 
                        lazyLoad={true} 
                        showThumbnails={false}
                    />
                }
            </Modal>
        }  
        
        <ProjectScreensBackground>
            <ProjectScreensWrapper
                // Анимацию пока выключил, что-то подглючивает иногда
                // variants={animationContainer}
                // initial="hidden"
                // whileInView="visible"
                // viewport={{ once: animationOnce }}
            >
                {props.projectScreens?.map((screen) => (                    
                    <ProjectScreen 
                        key={screen.id}
                        src={screen.thumbnail} 
                        // variants={animationItem} 
                        onClick={ () => showScreens(screen.id)}
                    />
                ))}                
            </ProjectScreensWrapper>


        </ProjectScreensBackground>
        </>
     );
}
 
export default ProjectScreens;
