import styled from "styled-components";


const FooterBlackBackground = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1b;
    min-height: 230px;
    padding: 40px 20px;
`

const FooterBlackWrapper = styled.div`
    width: 100%;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;    


    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    @media(max-width:920px) {
        justify-content: center;
    }       
`

const FooterBlackLogo  = styled.img`
    align-self: flex-start;
    margin-right: 30px;
    margin-bottom: 30px;
`

const FooterBlackAddress  = styled.div`
    align-self: flex-start;
    color: #ebebeb;
    font-size: 0.95rem;
    font-weight: 400;
    margin-right: 20px;
`

const FooterBlackAddressItem  = styled.div`    
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 40px;

    img {
        align-self: center;
        margin-right: 20px;
    }
`

const FooterBlackSocialLinks  = styled.div`
    align-self: flex-start;
    min-width: 200px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    margin-bottom: 20px;

    img {
        transition: .3s ease-in-out;
        margin-right: 15px;        
    }
    img:hover {
        transform: scale(1.20);
    }
`

const FooterBlackCopyright  = styled.div`
    padding-left: 80px;
    color: #ebebeb;
    font-size: 0.95rem;

    @media(max-width:920px) {
        /* text-align: center; */
        padding-top: 20px;
        padding-left: 0;
    }     
`

const FooterBlack = () => {
    return ( 
        <FooterBlackBackground>
            <FooterBlackWrapper>
                <FooterBlackLogo src="/images/footer_logo.png" />
                <FooterBlackAddress>
                    <FooterBlackAddressItem>
                        <img src="/images/footer_location_icon.png" />
                        <p>                    
                            16 Carlisle St, Soho, London, W1D 3BT England <br/>
                            +44 (0) 207 7577467
                        </p>
                    </FooterBlackAddressItem>
                    <FooterBlackAddressItem>
                        <img src="/images/footer_location_icon.png" />
                        <p>                    
                            6 Rodou Agioi Omologites, Nicosia, 1086  Cyprus <br/>
                            +357 96 821057
                        </p>
                    </FooterBlackAddressItem>                    
                </FooterBlackAddress>
                <FooterBlackSocialLinks>
                    <a href='/' target={'_blank'}>
                        <img src='/images/social_networks/youtube.png' />
                    </a>
                    <a href='/' target={'_blank'}>
                        <img src='/images/social_networks/linkedin.png' />
                    </a>
                    {/* <a href='/' target={'_blank'}>
                        <img src='/images/social_networks/facebook.png' />
                    </a>
                    <a href='/' target={'_blank'}>
                        <img src='/images/social_networks/twitter.png' />
                    </a>                     */}
                </FooterBlackSocialLinks>
            </FooterBlackWrapper>
            <FooterBlackCopyright>
                &copy; Magic Frame Animation, {new Date().getFullYear()}
            </FooterBlackCopyright>
        </FooterBlackBackground>
     );
}
 
export default FooterBlack;