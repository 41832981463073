import React from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';


const StyledVideoContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
`

const StyledVideo = styled.video`
    width: 100%;
    object-fit: cover;

    @media(min-width:1280px) {
        /* height: 90vh; */
    }     
`

const Video = (props) => {

    // useEffect (() => {
    //     const player = document.getElementById('MainVideo')
    //     if (player != null) {
    //         player.play()
    //     }
    // },[])
    
    return (
        <StyledVideoContainer>
            <StyledVideo controls autoPlay loop muted playsinline id="MainVideo">
                <source src={props.videoSrc} type="video/mp4" />
            </StyledVideo>            
        </StyledVideoContainer>
    );
}
 
export default Video;
