import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import SectionLable from '../components/SectionLable/SectionLable';
import SectionLableContainer from '../components/SectionLable/SectionLableContainer';
import BurgerMenu from '../components/Header/BurgerMenu';


function NotFound() {
    return (
      <>
        <BurgerMenu/>
        <Header/>
        <SectionLableContainer marginTop={'50px'}>
          <SectionLable>
              Page not found
          </SectionLable>
        </SectionLableContainer>
        <Footer/>
      </>
    );
  }
  
  export default NotFound;
  