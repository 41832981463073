import Header from '../components/Header/Header';
import WideSlider from '../components/WideSlider/WideSlider';
import AboutHome from '../components/About/AboutHome';
import News from '../components/News/News';
import BurgerMenu from '../components/Header/BurgerMenu';
import Video from '../components/Video/Video';
import { Outlet } from "react-router-dom";
import { MainPageSlides } from "../data/MainPageSlides"
import ProjectsListHome from '../components/Projects/ProjectListHome';
import Footer from '../components/Footer/Footer';


function HomePage() {
  
  return (
      <>
        
        {/* Место для размещения модального окна с новостью */}
        <Outlet/>

        <BurgerMenu/>
        <Header/>
        <Video videoSrc="/video/showreel.mp4" />
        <AboutHome/>        
        <WideSlider slidesList={MainPageSlides} />
        <ProjectsListHome/>    
        <News/>
        <Footer/>
      </>
    );
  }
  
  export default HomePage;
  